<template>
  <b-container class="integrator-service-create">
    <validation-observer ref="createService">
      <b-row>
        <b-col cols="6" class="d-flex justify-content-end">
          <b-card class="integrator-service-create-card p-1 mb-1" style="width: 500px">
            <p class="integrator-service-create-card-title text-light mb-2">{{ $t('integrator.pages.create_service.general_info') }}</p>

            <b-form-group
              label-for="service-name"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.create_service.service_name') }}
                </div>
              </template>
              <validation-provider
                #default="{ errors }"
                :name="$t('integrator.pages.create_service.service_name') "
                rules="required"
              >
                <b-form-input
                  id="service-name"
                  :placeholder=" $t('integrator.pages.create_service.service_name')"
                  :state="setInputState('name', 'required', errors)"
                  v-model.trim="create_service.name"
                  autofocus
                  @blur="setIdentifierByName"
                />
                <p class="text-danger description-under-desc">{{ errors[0] }}</p>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="service-identifier"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.create_service.identifier') }}
                </div>
              </template>
              <validation-provider
                #default="{ errors }"
                :name="$t('integrator.pages.create_service.identifier') "
                rules="required|alpha-dash"
              >
                <b-form-input
                  id="service-identifier"
                  :placeholder="$t('integrator.pages.create_service.identifier')"
                  :state="setInputState('identifier', 'required', errors)"
                  v-model="create_service.identifier"
                />
                <p class="text-danger description-under-desc">{{ errors[0] }}</p>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="service-category"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.create_service.category') }}
                </div>
              </template>
              
              <validation-provider
                #default="{ errors }"
                :name="$t('integrator.pages.create_service.category') "
                rules="required"
              >
                <v-select
                  v-if="Array.isArray(service_categories)"
                  id="service-category"
                  :state="setInputState('category', 'required', errors)"
                  :options="service_categories"
                  :clearable="false"
                  v-model="create_service.category"
                />
                <b-skeleton v-else height="37px" width="100%"/>
                <p class="text-danger description-under-desc">{{ errors[0] }}</p>
              </validation-provider>
            </b-form-group>
            <div align="right" v-if="$can('create', 'integratorCategory')">
              <b-button class="py-25 px-50" variant="outline-dark" @click="$refs['create-category-modal'].show()">
                <b-icon icon="plus" scale="0.8"/>
                <span class="small">Create Category</span>
              </b-button>
            </div>

            <b-form-group
              label-for="service-description"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.create_service.description')  }}
                </div>
              </template>

              <validation-provider
                #default="{ errors }"
                :name="$t('integrator.pages.create_service.description') "
                rules="max:300"
              >
                <b-form-textarea
                  id="service-description"
                  :placeholder="$t('integrator.pages.create_service.desc_about') "
                  rows="5"
                  :state="setInputState('short_description', 'required', errors)"
                  no-auto-shrink
                  no-resize
                  v-model.trim="create_service.short_description"
                />
                <p class="description-under-desc m-0 float-right" :class="errors.length ? 'text-danger' : 'text-muted'">{{ errors.length ? errors[0] : '300 letters max.' }}</p>
              </validation-provider>
            </b-form-group>

            <p class="integrator-service-create-card-title text-light mb-2 mt-3">{{ $t('integrator.pages.create_service.api_data') }}</p>

            <b-form-group
              label-for="service-host"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.create_service.host')  }}
                </div>
              </template>

              <validation-provider
                #default="{ errors }"
                :name="$t('integrator.pages.create_service.host') "
                rules="required"
              >

                <b-form-input
                  id="service-host"
                  :placeholder="$t('https://api.example.com.br')"
                  :state="setInputState('host', 'required', errors)"
                  v-model.trim="create_service.host"
                />
                <p class="text-danger description-under-desc">{{ errors[0] }}</p>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="service-docs-link"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.create_service.docs_link') }}
                </div>
              </template>
              <b-form-input
                id="service-docs-link"
                :placeholder="$t('https://www.example.com.br/docs')"
                :state="setInputState('docs_uri', 'optional')"
                v-model.trim="create_service.docs_uri"
              />
            </b-form-group>

            <p class="integrator-service-create-card-title text-light mb-1 mt-3">{{ $t('Privacidade') }}</p>

            <b-row>
              <b-col cols="6" class="pr-50" v-for="(button, index) in privacyButtonList" :key="button.label">
                <description-button
                  :key="`${button.title}${index}`"
                  :title="button.title"
                  :icon="button.icon"
                  :description="button.description"
                  v-model="privacyButtonList[index].active"
                  @selected="updateButtonList(index)"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-start">
          <preview-card
            :value="create_service"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col cols="6" class="d-flex justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mx-50"
          :to="{ name: 'integrator-service' }"
        >
        {{ $t('integrator.pages.create_service.cancel') }}
        </b-button>
        <b-button
          :disabled="isSaving"
          class="integrator-service-create-save-button ml-50"
          @click="createService"
        >
          <feather-icon 
            v-if="!isSaving"
            icon="PlusIcon"
          />
          <b-spinner v-else style="width: 1rem; height: 1rem;" label="Loading..." />
          {{ $t('integrator.pages.create_service.confirm') }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      ref="create-category-modal" 
      @ok="createCategory()"
      ok-title="Confirm"
      size="md"
      centered
    >
      <template #modal-title>
        <div>
          Creating Category
        </div>
      </template>
      
      <span class="small">Current categories:</span>
      <div class="d-flex justify-content-start flex-wrap py-1">
        <b-badge v-for="(cat, index) in service_categories" class="mx-50 mb-75 text-black" :key="'cat-list-'+cat.id" variant="light-purple">
          {{cat.label}}
        </b-badge>
      </div>
      
      <label>New Category name:</label>
      <b-form-input v-model="modalInfo.newCatName"/>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BCol,
  BRow,
  BContainer,
  BCard,
  BCardImg,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BSpinner,
  BSkeleton,
  BBadge
} from 'bootstrap-vue';
import VSelect from 'vue-select';
import DescriptionButton from '@/layouts/components/DescriptionButton.vue';
import { mapGetters } from 'vuex';
import PreviewCard from '@/views/pages/integrator/components/CreateService/PreviewCard.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, alphaDash } from '@validations'
import { makeToast } from '@/layouts/components/Popups.js'
import { max } from '@/utils/validations'

  export default {
    components: {
      BCol,
      BRow,
      BContainer,
      BCard,
      BCardImg,
      BFormInput,
      BFormGroup,
      BFormTextarea,
      BButton,
      BAvatar,
      BSpinner,
      VSelect,
      DescriptionButton,
      PreviewCard,
      ValidationObserver, 
      ValidationProvider,
      BSkeleton,
      BBadge,
    },
    data() {
      return {
        required,
        max,
        alphaDash,
        create_service: {
          name: '',
          identifier: '',
          category: null,
          short_description: '',
          avatar_img: new FormData(),
          cover_img: new FormData(),
          host: '',
          docs_uri: '',
          privacy: ''
        },
        privacyButtonList: [
          {
            "title": this.$t('integrator.pages.create_service.private'),
            "icon": "LockIcon",
            "description": this.$t('integrator.pages.create_service.private_about'),
            "active": true,
            "value": false
          },
          {
            "title": this.$t('integrator.pages.create_service.public'),
            "icon": "GlobeIcon",
            "description": this.$t('integrator.pages.create_service.public_about'),
            "active": false,
            "value": true
          },
        ],
        isSaving: false,
        modalInfo:{
          newCatName: ''
        }
      }
    },
    computed: {
      ...mapGetters('serviceModule', ['getIntegratorServiceCategories']),
      service_categories() {
        return this.getIntegratorServiceCategories
      },
      organizationID() {
        return localStorage.getItem('selectedOrganization')
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.getAllServiceCategories()
        this.create_service.privacy = this.privacyButtonList[0].value
      },  
      getAllServiceCategories() {
        this.$store.dispatch('serviceModule/fetchIntegratorServiceCategories').then(() => {
        }).catch((err) => {
          makeToast({
              title: "Error!" ,
              text: "Something went wrong.",
              variant: "danger",
              icon: "XIcon",
            });
        })
      },
      updateButtonList(index) {
        this.privacyButtonList.forEach((_, pos) => {
          if (pos != index) {
            this.$set(this.privacyButtonList[pos], 'active', false)
          }
        })
        this.create_service.privacy = this.privacyButtonList[index].value
        console.log(this.privacyButtonList[index].active, this.create_service.privacy)
      },
      createService() {
        this.$refs.createService.validate().then((success) => {
          if (success) {
            this.isSaving = true

            this.$store.dispatch('serviceModule/createIntegratorService', { ...this.create_service, organizationID: this.organizationID }).then((resp) => {
              makeToast({
                title: this.$t('integrator.pages.create_service.toasts.service_created.title') ,
                text: this.$t('integrator.pages.create_service.toasts.service_created.text'),
                variant: "success",
                icon: "CheckIcon",
              });
              this.isSaving = false

              this.$router.push({ name: 'integrator-service-edit', params: { serviceID: resp.id }})
              
            }).catch((error) => {
              console.error(error)
              this.isSaving = false
              makeToast({
                title: this.$t('common.toast.error.title') ,
                text: this.$t('common.toast.error.message'),
                variant: "danger",
                icon: "XIcon",
              });
            })
          }
        })
      },
      setInputState(key, type, errors = new Array) {
        switch (type) {
          case 'required': {
            return errors.length > 0 ? false : this.create_service[key] ? true : null
          } break;
          case 'optional': {
            return this.create_service[key] ? true : null
          } break;
        }
      },
      setIdentifierByName() {
        if (!this.create_service.identifier) {
          this.$set(this.create_service, 'identifier', this.create_service.name.replaceAll(" ", "_").toLowerCase())
        }
      },
      createCategory(){
        let name = this.modalInfo.newCatName
        this.$store.dispatch('serviceModule/createIntegratorCategory', {name: name}).then((resp)=>{
          makeToast({
            title:'Success!',
            text: "Category successfully created",
            variant: "success",
            icon: 'CheckIcon'
          })
          this.modalInfo.newCatName = ''
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

.integrator-service-create {
  .integrator-service-create-card {
    .integrator-service-create-card-title {
      font-weight: 500 !important;
      font-size: 18px !important;
      line-height: 22px !important;
    }
    .integrator-service-card-label {
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 18px !important;
      color: #D0D2D6 !important;
    }
    .description-under-desc {
      font-size: 10px !important;
    }
  }
  .integrator-service-create-save-button{
    background-color: #7367F0 !important;
    border-radius: 5px !important;
  }
}
</style>